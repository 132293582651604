import(/* webpackMode: "eager" */ "/vercel/path0/components/countdown-form/client.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/header/client.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/inline-form/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/link/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/max-width/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/mobile-menu/client.js");
import(/* webpackMode: "eager" */ "/vercel/path0/components/video-animation/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/companies/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/company-bubble-list/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/comparative-table/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/contact-form/form.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/contact-form/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/countdown-hero/background.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/countdown-hero/client.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/countdown-hero/form.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/countdown-hero/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/cta-item/countdown-footer/client.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/cta-item/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/button/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/expert/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/experts/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/founder/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/future-courses/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/hero-about/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/hero-form/form.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/hero-formation/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/hero-home/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/hero/client.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/horizontal-line/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/hero-form/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/image-text/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/landing-form/client.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/landing-heading/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/landing-program/client.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/landing-section/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/landing-modules/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/landing-testimonials/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/level-up/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/line-formations/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/module-list/client.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/offers-cards/client.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/questions-answers/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/questions-cards/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/quote/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/reasons/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/slider-ressources/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/slider-team/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/team-text/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/timeline/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/text/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/title-section/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/shared-sections-import/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/group/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/footer/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/layout/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/landing-header/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/landing-footer/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/heading/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/top-formation/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/tutors/client.js");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/tutors/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/unlock/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/waiting-list-form/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/countdown-form/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/countdown-form/timer.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/clients/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/comment-cards/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/comment-cards/item.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/banner/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/calendar/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/calendar/item.css");
import(/* webpackMode: "eager" */ "/vercel/path0/sections/video-text/index.js")